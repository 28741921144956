<template>
  <Teleport to="#modals">
    <div
      class="modal"
      id="brand-sale-exclusive"
      :class="{ open: show }"
      v-if="layout != 4"
    >
      <div class="modal-medium">
        <div class="modal-header d-flex align-items-center justify-content-between">
          <h3>Thương hiệu khuyến mãi</h3>

          <button class="dosiin_btn-close-modal" @click="closeModal">
            <i class="dsi dsi-close"></i>
          </button>
        </div>

        <div class="modal-body">
          <div class="brands-sale scroll-box-modal">
            <InfiniteLoadModal
              @infinite-scroll="checkIsLoadingNextPage"
              :isLoading="lazyLoad"
              :noResult="endLoad"
              :element="`.brands-sale`"
            >
              <template v-if="brands.length !== 0">
                <div class="quantity-brand-sale">
                  <div class="tilte">
                    <b v-text="eventData.companies_count"></b> thương hiệu đang khuyến mãi
                  </div>
                </div>

                <div class="box-brand-sale">
                  <div
                    class="brand-description_wrapper"
                    v-for="(brand, i) in brands"
                    :key="i"
                  >
                    <div class="brand-description_img brand-item_img-wrapper">
                      <router-link
                        :to="
                          brand.seo_name
                            ? _brandUrl(brand.seo_name)
                            : _brandUrl(brand.company_data.seo_name)
                        "
                      >
                        <img
                          width="62"
                          height="62"
                          v-lazy="_companyAvatar(brand.logos.theme)"
                          class="brand-top-selling-item_img"
                          :alt="brand.company"
                        />
                      </router-link>
                    </div>
                    <div class="brand-description_info">
                      <router-link :to="_brandUrl(brand.seo_name)" :title="brand.company">
                        <h5
                          class="brand-item_title"
                          :title="brand.company"
                          v-text="brand.company"
                        ></h5>
                      </router-link>

                      <dl class="brand-item_social">
                        <dd>
                          <b>{{ brand.follow_count }} </b>
                          <span>người theo dõi</span>
                        </dd>
                        <dd>
                          <b>{{ brand.like_count }} </b>
                          <span>sản phẩm</span>
                        </dd>
                      </dl>
                    </div>
                    <div class="brand-description_follow">
                      <FollowButton
                        @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                        class="brand_follow-btn dosiin_brand_follow-btn primary-button follow-with-text-btn"
                        followable_type="brand"
                        :followable_id="brand.company_id"
                        :is_follow="brand.is_follow"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </InfiniteLoadModal>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal modal-exclusive"
      :class="{ open: show, 'modal-full-screen': show }"
      id="modal-exclusive"
      v-else
    >
      <div class="modal-header">
        <div
          class="sticky-header brand-detail-sticky-header mode-sticky"
          id="dosiin_header-sticky"
        >
          <div class="header-left">
            <button class="scroll-to-btn" @click="closeModal">
              <i class="dsi dsi-chevron-left"></i>
            </button>
          </div>
          <div class="header-center">
            <h2 class="text-grey-lg">Thương hiệu khuyến mãi</h2>
          </div>
          <div class="header-right"></div>
        </div>
      </div>

      <div class="modal-body brands-sale-body">
        <div class="brands-sale">
          <InfiniteLoadModal
            @infinite-scroll="checkIsLoadingNextPage"
            :isLoading="lazyLoad"
            :noResult="endLoad"
            :element="`.brands-sale-body`"
          >
            <template v-if="brands.length !== 0">
              <div class="quantity-brand-sale">
                <div class="tilte">
                  <b v-text="eventData.companies_count"></b> thương hiệu đang khuyến mãi
                </div>
              </div>

              <div class="box-brand-sale">
                <div
                  class="brand-description_wrapper"
                  v-for="(brand, i) in brands"
                  :key="i"
                >
                  <router-link
                    :to="
                      brand.seo_name
                        ? _brandUrl(brand.seo_name)
                        : _brandUrl(brand.company_data.seo_name)
                    "
                    class="box-brand-sale-link"
                  >
                    <img
                      width="62"
                      height="62"
                      v-lazy="_companyAvatar(brand.logos.theme)"
                      class="brand-top-selling-item_img"
                      :alt="brand.company"
                    />
                    <div class="brand-description_info">
                      <h5 :title="brand.company" v-text="brand.company"></h5>
                      <dl class="brand-item_social">
                        <dd>
                          <b>{{ brand.follow_count }} </b><span>người theo dõi</span>
                        </dd>
                        <dd>
                          <b>{{ brand.like_count }} </b><span>sản phẩm</span>
                        </dd>
                      </dl>
                    </div>
                  </router-link>
                  <div class="brand-description_follow">
                    <FollowButton
                      @update:modelValue="(newValue) => (brand.follow_count = newValue)"
                      class="brand_follow-btn dosiin_brand_follow-btn primary-button follow-with-text-btn"
                      followable_type="brand"
                      :followable_id="brand.company_id"
                      :is_follow="brand.is_follow"
                    />
                  </div>
                </div>
              </div>
            </template>
          </InfiniteLoadModal>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
import { toRefs, ref, watch, inject } from "vue";
import InfiniteLoadModal from "@/components/common/InfiniteScrollModal";
import BrandRankingVerticalSkeleton from "@/components/skeletons/sections/BrandVerticalRanking";
import { serialize } from "@/utlis/serialize";
import BrandServices from "@/services/brand";
import { useStore } from "vuex";

export default {
  components: {
    InfiniteLoadModal,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    eventData: {
      type: Object,
      default: null,
    },
    buttonType: {
      type: String,
      default: "use",
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const currentUser = inject("currentUser");
    const show = ref(props.modalValue);
    const brands = ref([]);
    const loading = ref(true);
    const lazyLoad = ref(false);
    const endLoad = ref(false);

    const pagination = ref({});
    let params = {
      getBrand: true,
      get_banner: true,
      get_follow: true,
      page: 1,
      items_per_page: 20,
      use_caching: true,
    };
    pagination.value = params;

    const checkIsLoadingNextPage = () => {
      if (!lazyLoad.value && !endLoad.value) {
        pagination.value.company_id = props.eventData.company_ids;
        getBrands();
      }
    };

    async function getBrands() {
      lazyLoad.value = true;
      const response = await BrandServices.fetchBrands(serialize(pagination.value));
      brands.value.push(...response.data.brands);

      if (response.data.brands.length === 0) {
        endLoad.value = true;
        lazyLoad.value = false;
        return;
      }

      pagination.value.page = pagination.value.page + 1;

      lazyLoad.value = false;

      loading.value = false;
    }

    watch(
      () => props.modelValue,
      (isOpen) => {
        show.value = isOpen;
        if (show.value) {
          store.dispatch("modal/increase");

          pagination.value.company_id = props.eventData.company_ids;
          getBrands();
        } else {
          store.dispatch("modal/decrease");
        }
      }
    );

    const closeModal = () => {
      show.value = false;
      emit("update:modelValue", show.value);
    };

    return {
      show,
      loading,
      lazyLoad,
      endLoad,
      currentUser,
      ...toRefs(props),
      brands,
      closeModal,
      checkIsLoadingNextPage,
    };
  },
};
</script>

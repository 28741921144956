<template>
  <div
    class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top main-page-brand-sale"
  >
    <LoadingComponent v-if="loading" />
    <div class="main-page-brand-sale" v-else>
      <div class="brand-sale-bg">
        <!-- <img
          src="https://h.dosi-in.com/file/vnfd/375/dosiin-070113375544.jpg?w=670&amp;h=670&amp;fit=crop&amp;fm=webp"
          alt=""
        /> -->
      </div>
      <div class="content-page-brand-wrapper">
        <div class="content-page-brand">
          <div class="title-page d-flex align-items-center justify-content-between">
            <div class="title-all-categories">Sale độc quyền</div>
            <div class="tab-bar-content-quantity" v-if="brands.length !== 0">
              <div class="avatar-see-more-brand list-brand-sale">
                <div class="detail-avatar" v-for="(brand, b) in brands" :key="b">
                  <BrandLogo :brand="brand" :width="24" :height="24" />
                </div>

                <div
                  class="blur"
                  v-if="eventData.event.companies_count > 4"
                  @click.prevent="state.showListModal = true"
                >
                  <i class="dsi dsi-more-h"></i>
                </div>
              </div>
              <div class="text-quantity">
                {{ eventData.event.companies_count }} thương hiệu
              </div>
            </div>
          </div>
        </div>
        <Swiper
          @slideChange="countSlider"
          @swiper="getProductId"
          class="slider-brand-sale-exclusive overflow-unset"
          :modules="modules"
          :slidesPerView="options.slidesPerView"
          :spaceBetween="options.spaceBetween"
          :centeredSlides="options.centeredSlides"
          :grabCursor="options.grabCursor"
          :coverflowEffect="options.coverflowEffect"
          :effect="options.effect"
          :navigation="options.navigation"
          :pagination="options.pagination"
        >
          <SwiperSlide
            v-for="(product, i) in products"
            :key="i"
            class="content-brand-sale"
            :data-id-product="product.product_id"
          >
            <div class="action-brand-sale">
              <router-link :to="_productUrl(product)" :title="product.product">
                <img
                  v-lazy="
                    _productThumbnailSticker(product.main_pair, product.sticker.main_pair)
                  "
                  :data-bg="_productThumbnail(product.main_pair)"
                  :alt="product.product"
                />
              </router-link>
              <div class="content-brand">
                <div class="detail-brand">
                  <router-link
                    class="decription-brand-sale"
                    :to="_brandUrl(product.seo_name_company)"
                    :title="product.company"
                  >
                    <div class="title-name dosiin_ml-0">
                      <div class="name" v-text="product.company"></div>
                      <div class="decription" v-text="product.product"></div>
                    </div>
                  </router-link>
                  <span v-if="product.list_discount_prc">
                    -{{ Math.ceil(product.list_discount_prc) }}%
                  </span>
                </div>
              </div>
            </div>
          </SwiperSlide>

          <template v-if="state.lazyLoad">
            <SwiperSlide v-for="i in 3" :key="i" class="content-brand-sale">
              <div class="action-brand-sale load-more">
                <Skeletor height="464" width="100%" />
              </div>
            </SwiperSlide>
          </template>


          <div
            v-if="options.navigation"
            class="swiper-button-next dosiin_swiper-button dosiin_position-button-next btn-brand-sale-prev dosiin_swiper-button-gradient"
            :class="state.lazyLoad ? 'dosiin-hidden' : ''"
          ></div>
          <div
            v-if="options.navigation"
            class="swiper-button-prev dosiin_swiper-button dosiin_position-button-prev btn-brand-sale-next dosiin_swiper-button-gradient"
          ></div>
        </Swiper>

          <div class="action-brand">
            <button
              class="detail-action _dosiin_mr-4"
              :class="`${state.id_product}`"
              @click="showAddToCart(state.id_product)"
            >
              <img
                src="https://dosi-in.com/images/assets/icons/add_to basket.png"
              />
            </button>

            <ProductVariantExclusive
              :key="state.productPick.product_id"
              v-model="state.showModalAddCart"
              :product="state.productPick"
              @update:modelValue="(val) => (state.showModalAddCart = val)"
            />
            <!--end: popup-->
          </div>
      </div>

      <BrandsListModal v-model="state.showListModal" :eventData="eventData.event" />
    </div>
  </div>
</template>

<script>
import { inject, ref, reactive, watch } from "vue";
import ProductLoader from "@/components/product/ProductLoader";
import ProductService from "@/services/product";
import { Navigation, Pagination, EffectCoverflow } from "swiper";
import ProductVariantExclusive from "@/components/modals/ProductVariantExclusive";
import BrandsListModal from "@/components/modals/BrandListExclusive";

export default {
  components: {
    ProductLoader,
    ProductVariantExclusive,
    BrandsListModal,
  },
  setup() {
    const params = inject("params");
    const loading = inject("loading");
    const pairsObject = inject("pairsObject");
    const totalProducts = ref(0);
    const eventData = inject("event");
    const maxPrice = ref(0);
    const products = inject("products");
    const brands = inject("brands");
    const options = {
      effect: "coverflow",
      loop: true,
      spaceBetween: 80,
      centeredSlides: true,
      slidesPerView: "4",
      grabCursor: false,
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      coverflowEffect: {
        rotate: 40,
        stretch: 0,
        depth: 100,
        modifier: 1,
        scale: 0.8,
      },
    };
    const pagination = inject("pagination");
    const state = reactive({
      lazyLoad: false,
      id_product: "",
      showModalAddCart: false,
      productPick: {},
      showListModal: false,
    });
    let slide_data = ref(null);

    var productList = [];
    let positionSlide = ref(null);

    const countSlider = (swiper) => {
      CountPosition(swiper);
    };

    const getProductId = (swiper) => {
      slide_data = swiper.visibleSlides[0];
      state.id_product = slide_data.getAttribute("data-id-product");
      setBackgroundImageSlide(slide_data, ".brand-sale-bg");
    };

    async function CountPosition(swiper) {
      state.showModalAddCart = false;
      if (swiper.previousIndex === 1 && swiper.realIndex === 0) {
        slide_data = swiper.visibleSlides[0];
      } else {
        if (swiper.activeIndex > 1) {
          slide_data = swiper.visibleSlides[2];
        } else {
          slide_data = swiper.visibleSlides[1];
        }
      }

      setBackgroundImageSlide(slide_data, ".brand-sale-bg");

      state.id_product = slide_data.getAttribute("data-id-product");

      positionSlide = swiper.activeIndex;
      let defaul_slide = pagination.value.items_per_page * pagination.value.page - 2;

      if (positionSlide > swiper.previousIndex && positionSlide == defaul_slide) {
        getProducts();
      }
    }

    async function getProducts() {
      state.lazyLoad = true;

      pagination.value.page = pagination.value.page + 1;

      const response = await ProductService.fetchProducts(pagination.value);
      products.value.push(...response.data.products);

      // // end of post data
      if (response.data.products === 0) {
        state.lazyLoad = false;
        return;
      }

      pagination.value = response.data.params;
      state.lazyLoad = false;
    }

    async function showAddToCart(product_id) {
      state.showModalAddCart = true;
      let p = productList.find((product) => product.product_id == product_id);

      if (p) {
        state.productPick = p;
        return;
      }
      state.productPick = {};

      getDetailProduct(product_id);
    }

    async function getDetailProduct(product_id) {
      const response = await ProductService.fetchProduct(product_id);

      if (response && response.data) {
        state.productPick = response.data;
        productList.push(response.data);
      }
    }

    function setBackgroundImageSlide(eleSlide, eleSet) {
      let imgSrc = eleSlide.querySelector(".action-brand-sale img");
      const targetEle = document.querySelector(eleSet);
      if (targetEle) {
        targetEle.style.backgroundImage = "url(" + imgSrc.getAttribute("data-bg") + ")";
      }
    }

    return {
      state,
      params,
      eventData,
      loading,
      pairsObject,
      totalProducts,
      maxPrice,
      products,
      brands,
      pagination,
      options,
      modules: [Navigation, Pagination, EffectCoverflow],
      CountPosition,
      countSlider,
      getProducts,
      getProductId,
      showAddToCart,
    };
  },
};
</script>
<style>
.main-page-brand-sale .action-brand{
  z-index: 10 !important;
}
.main-page-brand-sale .btn-brand-sale-next{
  position: absolute;
left: 42%;
bottom: -70px !important;
top: unset;
}
.main-page-brand-sale .btn-brand-sale-prev{
  position: absolute;
top: unset;
bottom: -70px !important;
right: 42%;
}
/* .slider-brand-sale-exclusive {
  height: 60vh;
} */
/* .main-page-brand-sale .action-brand {
  width: fit-content;
  margin: 16px auto 0;
  top: -11.5%;
  cursor: unset;
} */
/* .main-page-brand-sale .action-brand .detail-action {
  position: relative;
  z-index: 1;
} */
/* .dosiin-details-page .icon_dropdow_detail {
  left: 47%;
} */
/* .action-brand .dosiin-details-page {
  width: 465px;
} */

/* .main-page-brand-sale .swiper-slide::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 466px;
  left: 0px;
  top: 0px;
  background: rgba(16, 16, 16, 0.3);
  right: 0;
  border-radius: 8px;
} */

/* .main-page-brand-sale .swiper-slide.swiper-slide-active::after {
  content: unset;
} */
/* 
.swiper-3d .swiper-slide-shadow-right,
.swiper-3d .swiper-slide-shadow-left {
  height: 466px;
} */

/* .main-page-brand-sale .brand-sale-bg {
  height: calc(100vh - 82px);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
} */
</style>

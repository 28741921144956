<template>
  <div
    class="dosiin-details-page position-exclusive"
    :class="show ? 'open' : ''"
    id="position-exclusive"
    v-if="layout != 4"
  >
    <div v-if="product.product_id">
      <i class="dsi dsi-fill-rankingdown icon_dropdow_detail"></i>
      <div class="exclusive-popup-header">
        <h3>Thêm vào giỏ hàng</h3>
        <div class="exclusive-popup-close" @click="closeMoDal" style="cursor: pointer">
          <i class="dsi dsi-s16 dsi-close"></i>
        </div>
      </div>
      <div class="exclusive-popup-product">
        <div class="exclusive-name">{{ product.product }}</div>
        <div class="exclusive-sale">{{ currencyFormat(product.list_price) }}</div>
        <div class="exclusive-price">
          <h4>{{ currencyFormat(product.price) }}</h4>
          <span v-if="product.list_discount_prc"
            >-{{ Math.ceil(product.list_discount_prc) }}%</span
          >
        </div>
      </div>

      <form id="dosiin_bs-submit" v-if="product.verify_status === 'A'">
        <div class="dosiin-details-body">
          <div
            v-if="state.showWarningAddToCart || state.showWarningAmount"
            class="product_details-properties"
          >
            <span class="" style="color: #f83b41" v-if="state.showWarningAddToCart">
              Vui lòng chọn Màu sắc, Kích thước, Số lượng của sản phẩm
            </span>
            <span style="color: #f83b41" v-if="state.showWarningAmount">
              Số lượng bạn chọn đã đạt mức tối đa của sản phẩm này
            </span>
          </div>

          <div
            v-if="state.existColor"
            class="product_details-properties"
            :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
          >
            <div class="bs-modal-select-color center dosiin_mt-2">
              <div class="color-list">
                <div
                  class="color-list-inner"
                  aria-live="polite"
                  style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px)"
                >
                  <div
                    class="select_properties_item"
                    v-for="(color, i) in colors"
                    :key="i"
                  >
                    <input
                      v-model="productData.color"
                      class="color-check-input"
                      type="radio"
                      :id="`color-${color.colorId}`"
                      :value="color.colorId"
                      :name="`productData[${product.product_id}][color]`"
                      @change="colorChange(color.colorId)"
                    />
                    <label
                      class="color-icon"
                      :for="`color-${color.colorId}`"
                      :style="{ 'background-color': color.code }"
                    ></label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            v-if="state.existSize"
            class="product_details-properties"
            :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
          >
            <div class="bs-modal-select-size">
              <div class="size-list">
                <div
                  class="size-list-inner"
                  aria-live="polite"
                  style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px)"
                >
                  <div class="select_properties_item" v-for="(size, i) in sizes" :key="i">
                    <input
                      class="size-check-input"
                      v-model="productData.size"
                      :id="`size-${size.sizeId}`"
                      type="radio"
                      :value="size.sizeId"
                      :name="`productData[${product.product_id}][size]`"
                      @change="sizeChange(size.sizeId)"
                    />
                    <label class="size-icon" :for="`size-${size.sizeId}`">
                      <span class="text" v-text="size.sizeName"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="exclusive-btn"
          :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
        >
          <div class="product_details-properties-box">
            <div class="number-input-group dosiin_number-input-group qty_number-inner">
              <button
                class="decrement-btn dosiin_decrement"
                type="button"
                @click="decrease"
              >
                <i class="dsi dsi-minus-2"></i>
              </button>
              <input
                class="bs-quantity-input"
                readonly
                type="number"
                :value="state.amount"
                :name="`productData[${product.product_id}][amount]`"
                :max="state.maxAmount ? state.maxAmount : state.totalAmount"
                ref="productQty"
              />
              <button
                class="increment-btn dosiin_increment"
                type="button"
                @click="increase"
              >
                <i class="dsi dsi-plus-2"></i>
              </button>
            </div>
          </div>

          <button @click.prevent="addProductToCart">
            <div class="add-product" v-if="!state.loadingAddToCart">
              <i class="dsi dsi-addbasket"></i><span> Thêm</span>
            </div>
            <pulse-loader
              v-else
              :loading="state.loadingAddToCart"
              color="#000000"
            ></pulse-loader>
          </button>
        </div>
      </form>

      <div
        class="dosiin_text-center dosiin_w-100"
        v-else-if="product.verify_status == 'D' && product.crawler_url.length"
        style="padding: 16px"
      >
        <div class="btn-rounded dossin_btn-whitebg dosiin_mb-1">
          <div class="btn-rounded_child">
            <a
              class="dosiin_add-to-cart"
              :href="product.crawler_url"
              target="_blank"
              rel="nofollow"
              style="display: flex; justify-content: center; align-items: center"
              @click="trackingCrawler"
            >
              <span class="text-pink-gradient dosiin_mr-2"> Đến trang mua hàng </span>
              <i class="dsi dsi-act-arrow-forward"></i>
            </a>
          </div>
        </div>
        <span class="note-add-to-cart">MẶT HÀNG KHÔNG BÁN TRỰC TIẾP BỞI DOSIIN</span>
      </div>
      <div class="dosiin_text-center dosiin_w-100" v-else style="padding: 16px">
        <button class="primary-button button add-to-cart" disabled="">
          <span class="primary-link_text">Tạm thời ngưng đặt hàng</span>
        </button>
      </div>
    </div>
    <LoadingComponent v-else />
  </div>
  <div
    v-else
    class="product-popup-wrapper"
    :class="show ? 'open' : ''"
    id="product-popup-cart"
  >
    <div class="exclusive-popup">
      <div class="exclusive-popup-header">
        <h3>Thêm vào giỏ hàng</h3>
        <button class="exclusive-popup-close" @click="closeMoDal">
          <i class="dsi dsi-s16 dsi-close"></i>
        </button>
      </div>
      <div v-if="product.product_id">
        <div class="exclusive-popup-product">
          <div class="exclusive-name">{{ product.product }}</div>
          <div class="exclusive-sale">{{ currencyFormat(product.list_price) }}</div>
          <div class="exclusive-price">
            <h4>{{ currencyFormat(product.price) }}</h4>
            <span v-if="product.list_discount_prc">
              -{{ Math.ceil(product.list_discount_prc) }}%
            </span>
          </div>
        </div>

        <form v-if="product.verify_status === 'A'">
          <div
            style="padding: 10px 12px"
            v-if="state.showWarningAddToCart || state.showWarningAmount"
          >
            <span class="" style="color: #f83b41" v-if="state.showWarningAddToCart">
              Vui lòng chọn Màu sắc, Kích thước, Số lượng của sản phẩm
            </span>
            <span style="color: #f83b41" v-if="state.showWarningAmount">
              Số lượng bạn chọn đã đạt mức tối đa của sản phẩm này
            </span>
          </div>

          <div
            class="color-list"
            v-if="state.existColor"
            :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
          >
            <div
              class="color-list-inner"
              aria-live="polite"
              style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px)"
            >
              <div class="select_properties_item" v-for="(color, i) in colors" :key="i">
                <input
                  v-model="productData.color"
                  class="color-check-input"
                  type="radio"
                  :id="`color-${color.colorId}`"
                  :value="color.colorId"
                  :name="`productData[${product.product_id}][color]`"
                  @change="colorChange(color.colorId)"
                />
                <label
                  class="color-icon"
                  :for="`color-${color.colorId}`"
                  :style="{ 'background-color': color.code }"
                ></label>
              </div>
            </div>
          </div>

          <div
            class="bs-modal-select-size"
            v-if="state.existSize"
            :class="[state.showWarningAddToCart ? $style.warningAddToCart : '']"
          >
            <div class="size-list">
              <div
                class="size-list-inner"
                aria-live="polite"
                style="transition-duration: 0ms; transform: translate3d(0px, 0px, 0px)"
              >
                <div class="select_properties_item" v-for="(size, i) in sizes" :key="i">
                  <input
                    class="size-check-input"
                    v-model="productData.size"
                    :id="`size-${size.sizeId}`"
                    type="radio"
                    :value="size.sizeId"
                    :name="`productData[${product.product_id}][size]`"
                    @change="sizeChange(size.sizeId)"
                  />
                  <label class="size-icon" :for="`size-${size.sizeId}`">
                    <span class="text" v-text="size.sizeName"></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div class="exclusive-btn">
            <div class="product_details-properties">
              <div class="number-input-group dosiin_number-input-group qty_number-inner">
                <button
                  class="decrement-btn dosiin_decrement"
                  type="button"
                  @click="decrease"
                >
                  <i class="dsi dsi-minus-2"></i>
                </button>
                <input
                  class="bs-quantity-input"
                  readonly
                  type="number"
                  :value="state.amount"
                  :name="`productData[${product.product_id}][amount]`"
                  :max="state.maxAmount ? state.maxAmount : state.totalAmount"
                  ref="productQty"
                />
                <button
                  class="increment-btn dosiin_increment"
                  type="button"
                  @click="increase"
                >
                  <i class="dsi dsi-plus-2"></i>
                </button>
              </div>
            </div>

            <button @click.prevent="addProductToCart">
              <div class="add-product" v-if="!state.loadingAddToCart">
                <i class="dsi dsi-act-addbasket"></i><span> Thêm</span>
              </div>
              <pulse-loader
                v-else
                :loading="state.loadingAddToCart"
                color="#000000"
              ></pulse-loader>
            </button>
          </div>
        </form>
        <div
          class="dosiin_text-center dosiin_w-100"
          v-else-if="product.verify_status == 'D' && product.crawler_url.length"
          style="padding: 16px"
        >
          <div class="btn-rounded dossin_btn-whitebg dosiin_mb-1">
            <div class="btn-rounded_child">
              <a
                class="dosiin_add-to-cart"
                :href="product.crawler_url"
                target="_blank"
                rel="nofollow"
                style="display: flex; justify-content: center; align-items: center"
                @click="trackingCrawler"
              >
                <span class="text-pink-gradient dosiin_mr-2"> Đến trang mua hàng </span>
                <i class="dsi dsi-act-arrow-forward"></i>
              </a>
            </div>
          </div>
          <span class="note-add-to-cart">MẶT HÀNG KHÔNG BÁN TRỰC TIẾP BỞI DOSIIN</span>
        </div>
        <div class="dosiin_text-center dosiin_w-100" v-else style="padding: 16px">
          <div class="btn-rounded dossin_btn-whitebg dosiin_mb-1">
            <button class="primary-button button add-to-cart" disabled="">
              <span class="primary-link_text">Tạm thời ngưng đặt hàng</span>
            </button>
          </div>
        </div>
      </div>
      <LoadingComponent v-else />
    </div>
  </div>
</template>

<script>
import Modal from "@/components/modals/Modal";
import CartUpdateAddress from "@/components/modals/CartUpdateAddress";
import cartService from "@/services/cart";
import trackingService from "@/services/tracking";

import { toRefs, ref, watch, inject, reactive, computed } from "vue";
import { _getSiblings } from "@/utlis/dom";
import { useStore } from "vuex";
import { useToast } from "vue-toastification";
import { _isValidAddress } from "@/utlis/cart";

export default {
  components: {
    Modal,
    CartUpdateAddress,
  },
  props: {
    product: {
      type: Object,
      default: {},
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const toast = useToast();
    const store = useStore();
    const product = props.product;
    const show = ref(props.modelValue);
    const productQty = ref(null);

    const state = reactive({
      showModalAddress: false,
      showWarningAmount: false,
      showWarningAddToCart: false,
      canAddToCart: false,
      showSizeChart: false,
      totalAmount: parseInt(product.amount),
      amount: 1,
      maxAmount: parseInt(product.amount),
      totalPrice: parseInt(product.price),
      loadingAddToCart: false,
      loadingBuyNow: false,
      existColor: product.product_colors && product.product_colors.colorId !== "0",
      existSize: product.product_sizes && product.product_sizes.sizeId !== "0",
    });

    const productData = reactive({
      product_id: product.product_id,
      amount: 1,
      color: null,
      size: null,
    });

    const sizes = product.product_sizes;
    const colors = product.product_colors;
    const colors_sizes = product.colors_sizes;
    const modalOpenCount = computed(() => store.getters["modal/countOpen"]);
    const currentUser = inject("currentUser");

    store.dispatch("modal/initModal");

    const closeMoDal = () => {
      show.value = !show.value;
      state.showWarningAddToCart = false;
      emit("update:modelValue", show.value);
    };

    const colorChange = (colorId) => {
      let color = colors_sizes.find((item) => item.colorId === colorId);
      // check color have size;
      if (color.productId && !state.existSize) {
        let sizesInColor = color.sizes;
        state.maxAmount = parseInt(sizesInColor[0].amount);
        productQty.value.setAttribute("max", state.maxAmount);
      }
      checkProductCanAddToCart();
    };

    const sizeChange = (sizeId) => {
      let sizes = colors_sizes[0].sizes;
      let size = sizes.find((item) => item.sizeId === sizeId);
      state.maxAmount = parseInt(size.amount);
      productQty.value.setAttribute("max", state.maxAmount);
      //reset amount
      state.amount = 1;
      //reset showWarningAmount
      state.showWarningAmount = false;
      checkProductCanAddToCart();
    };

    const decrease = () => {
      if (state.amount === 1) return false;
      state.showWarningAmount = false;
      state.amount = state.amount - 1;
    };

    const increase = () => {
      if (state.amount >= state.maxAmount) {
        state.showWarningAmount = true;
        return false;
      }
      state.showWarningAmount = false;
      state.amount = state.amount + 1;
    };

    async function addProductToCart($event, checkout = false) {
      if (!state.canAddToCart) {
        state.showWarningAddToCart = true;
        return;
      }

      if (checkout) state.loadingBuyNow = true;
      else state.loadingAddToCart = true;

      const response = await cartService.addProductToCart(productData);

      const trackingData = {
        uuid: localStorage.getItem("uuid"),
        userId: currentUser.value ? currentUser.value.user_id : 0,
        productId: product.product_id,
        categoryId: product.category_id,
        brandId: product.company_id,
        price: product.price,
        quantity: productData.amount,
        isCrawl: 0,
      };

      const resTracking = trackingService.postDataAddToCart(trackingData, product);

      if (response.data) {
        toast.success("Sản phẩm đã được thêm vào giỏ hàng");
        store.dispatch("cart/initCart", response.data);

        if (checkout) {
          window.location.href = "/checkout-checkout";
        }

        show.value = !show.value;
        emit("update:modelValue", show.value);
      }

      state.loadingAddToCart = false;
      state.loadingBuyNow = false;
      state.showWarningAddToCart = false;
    }

    async function checkProductCanAddToCart() {
      if (state.maxAmount === 0) {
        state.canAddToCart = false;
        state.showWarningAmount = false;
      } else if (state.existColor && state.existSize) {
        // check product exits color && exits size;
        if (productData.color && productData.size) state.canAddToCart = true;
        state.showWarningAddToCart = false;
      } else if (state.existColor) {
        // product exits color
        if (productData.color) state.canAddToCart = true;
        state.showWarningAddToCart = false;
      } else if (state.existSize) {
        // product exits size
        if (productData.size) state.canAddToCart = true;
        state.showWarningAddToCart = false;
      }
    }

    watch(
      () => props.modelValue,
      (isOpen) => {
        show.value = isOpen;
      }
    );

    const onInitSwiper = (sw, type) => {
      //const count = sw.slides.length;
    };

    return {
      state,
      productData,
      show,
      sizes,
      colors,
      //ref
      productQty,
      // swiper event
      onInitSwiper,
      closeMoDal,
      decrease,
      increase,
      sizeChange,
      colorChange,
      // api methods
      addProductToCart,
      ...toRefs(props),
    };
  },
};
</script>
<style>
.warningAddToCart {
  background-color: rgb(255 0 32 / 11%);
}
.note-add-to-cart {
  font-size: 12px;
}
</style>

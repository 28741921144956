<template>
  <main class="product-level1-page page-new-product">
    <LoadingComponent v-if="loading" />
    <template v-else>
      <BackHeader>
        <template v-slot:center>
          <div class="hotdeal-inner">
            <h2 class="hotdeal_title">
              <i class="dsi dsi-color-thunder"></i>
              <span>Sale độc quyền</span>
            </h2>
            <div class="hotdeal-inner-box">
              <span>{{ eventData.event.name }}</span>
              <!-- <ul class="dosiin_countdown hotdeal-countdown">
                <li class="dosiin_countdown_days">
                  <div class="number">: 00</div>
                </li>
                <li class="dosiin_countdown_hours">
                  <div class="number">: 00</div>
                </li>
                <li class="dosiin_countdown_minutes">
                  <div class="number">: 00</div>
                </li>
                <li class="dosiin_countdown_seconds">
                  <div class="number">: 00</div>
                </li>
              </ul> -->
            </div>
          </div>
        </template>
        <template v-slot:right>
          <div class="back-header-right-side">
            <router-link
              :to="{ name: 'search' }"
              class="search-icon dosiin_search-header-btn"
            >
              <i class="dsi dsi-search"></i>
            </router-link>
          </div>
        </template>
      </BackHeader>
      <section class="exclusive-section">
        <div class="exclusive-section-inner">
          <div class="background-exclusive">
            <!-- <img
              src="https://h.dosi-in.com/file/vnfd/189/dosiin-209666923_1463631820669343_866882398913928449_n189718.jpg?w=670&amp;h=670&amp;fit=crop&amp;fm=webp"
              alt=""
            /> -->
          </div>
          <div class="body-exclusive">
            <div class="tab-bar-content-quantity" v-if="brands.length !== 0">
              <div class="avatar-see-more-brand">
                <div class="detail-avatar" v-for="(brand, b) in brands" :key="b">
                  <BrandLogo :brand="brand" :width="24" :height="24" />
                  <i
                    class="dsi dsi-s16 dsi-more-h"
                    v-if="b == Object.keys(brands).length - 1"
                    @click.prevent="state.showListModal = true"
                  ></i>
                </div>
              </div>
              <p class="text-quantity">
                <b>{{ eventData.event.companies_count }} </b> thương hiệu đang khuyến mãi
              </p>
            </div>

            <!-- <div class="exclusive-swiper"> -->
            <Swiper
              @slideChange="countSlider"
              @swiper="getProductId"
              class="exclusive-swiper"
              :modules="modules"
              :slidesPerView="options.slidesPerView"
              :spaceBetween="options.spaceBetween"
              :centeredSlides="options.centeredSlides"
              
              
              :navigation="options.navigation"
              :pagination="options.pagination"
            >
              <SwiperSlide
                v-for="(product, i) in products"
                :key="i"
                :data-id-product="product.product_id"
              >
                <div class="exclusive-item-box">
                  <div class="exclusive-item">
                    <router-link
                      class="exclusive-item-link"
                      :to="_productUrl(product)"
                      :title="product.product"
                    >
                      <img
                        v-lazy="
                          _productThumbnailSticker(
                            product.main_pair,
                            product.sticker.main_pair
                          )
                        "
                        :data-bg="_productThumbnail(product.main_pair)"
                        :alt="product.product"
                        class="exclusive-item-img"
                      />
                    </router-link>
                    <div class="content-brand">
                      <router-link
                        class="detail-brand"
                        :to="_brandUrl(product.seo_name_company)"
                        :title="product.company"
                      >
                        <div class="title-name dosiin_ml-0">
                          <h3 v-text="product.company"></h3>
                          <p v-text="product.product"></p>
                        </div>
                      </router-link>
                      <span v-if="product.list_discount_prc">
                        -{{ Math.ceil(product.list_discount_prc) }}%
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

              <template v-if="state.lazyLoad">
                <SwiperSlide v-for="i in 3" :key="i">
                  <div class="exclusive-item-box load-more">
                    <Skeletor height="411" width="100%" />
                  </div>
                </SwiperSlide>
              </template>

              <div
                v-if="options.navigation"
                class="swiper-button-next exclusive-position exclusive-position-next"
                :class="state.lazyLoad ? 'dosiin-hidden' : ''"
              ></div>

              <div
                v-if="options.navigation"
                class="swiper-button-prev exclusive-position exclusive-position-prev"
              ></div>

              <div class="action-brand">
                <div
                  class="action-brand-item product-popup dosiin_mr-0"
                  :class="`${state.id_product}`"
                  @click="showAddToCart(state.id_product)"
                >
                  <i class="dsi dsi-s28 dsi-addbasket"></i>
                </div>
              </div>
            </Swiper>
          </div>
        </div>

        <ProductVariantExclusive
          :key="state.productPick.product_id"
          v-model="state.showModalAddCart"
          :product="state.productPick"
          @update:modelValue="(val) => (state.showModalAddCart = val)"
        />

        <BrandsListModal v-model="state.showListModal" :eventData="eventData.event" />
      </section>
    </template>
  </main>
</template>

<script>
import BackHeader from "@/components/common/BackHeader";
import { inject, ref, reactive, watch } from "vue";
import ProductLoader from "@/components/product/ProductLoader";
import ProductService from "@/services/product";
import { Navigation, Pagination } from "swiper";
import ProductVariantExclusive from "@/components/modals/ProductVariantExclusive";
import BrandsListModal from "@/components/modals/BrandListExclusive";

export default {
  components: {
    BackHeader,
    ProductLoader,
    ProductVariantExclusive,
    BrandsListModal,
  },
  setup() {
    const params = inject("params");
    const loading = inject("loading");
    const pairsObject = inject("pairsObject");
    const totalProducts = ref(0);
    const eventData = inject("event");
    const maxPrice = ref(0);
    const products = inject("products");
    const brands = inject("brands");
    const options = {
      //effect: "coverflow",
      loop: true,
      centeredSlides: true,
      slidesPerView: "1",
      grabCursor: false,
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      
      on: {},
    };
    const pagination = inject("pagination");
    const state = reactive({
      lazyLoad: false,
      id_product: "",
      showModalAddCart: false,
      productPick: {},
      showListModal: false,
    });
    let slide_data = ref(null);
    let active_num = ref(null);

    var productList = [];
    let positionSlide = ref(null);

    const countSlider = (swiper) => {
      CountPosition(swiper);
    };

    const getProductId = (swiper) => {
      active_num = swiper.activeIndex;
      slide_data = swiper.slides[active_num];
      state.id_product = slide_data.getAttribute("data-id-product");
      setBackgroundImageSlide(slide_data, ".background-exclusive");
    };

    async function CountPosition(swiper) {
      active_num = swiper.activeIndex;
      state.showModalAddCart = false;
      slide_data = swiper.slides[active_num];

      setBackgroundImageSlide(slide_data, ".background-exclusive");

      state.id_product = slide_data.getAttribute("data-id-product");

      positionSlide = active_num;
      let defaul_slide = pagination.value.items_per_page * pagination.value.page - 2;

      if (positionSlide > swiper.previousIndex && positionSlide == defaul_slide) {
        getProducts();
      }
    }

    async function getProducts() {
      state.lazyLoad = true;

      pagination.value.page = pagination.value.page + 1;

      const response = await ProductService.fetchProducts(pagination.value);
      products.value.push(...response.data.products);

      // // end of post data
      if (response.data.products === 0) {
        state.lazyLoad = false;
        return;
      }

      pagination.value = response.data.params;
      state.lazyLoad = false;
    }

    async function showAddToCart(product_id) {
      state.showModalAddCart = true;
      let p = productList.find((product) => product.product_id == product_id);

      if (p) {
        state.productPick = p;
        return;
      }
      state.productPick = {};

      getDetailProduct(product_id);
    }

    async function getDetailProduct(product_id) {
      const response = await ProductService.fetchProduct(product_id);

      if (response && response.data) {
        state.productPick = response.data;
        productList.push(response.data);
      }
    }

    function setBackgroundImageSlide(eleSlide, eleSet) {
      let imgSrc = eleSlide.querySelector(".exclusive-item img");
      const targetEle = document.querySelector(eleSet);
      if (targetEle) {
        targetEle.style.backgroundImage = "url(" + imgSrc.getAttribute("data-bg") + ")";
      }
    }

    return {
      state,
      params,
      eventData,
      loading,
      pairsObject,
      totalProducts,
      maxPrice,
      products,
      brands,
      pagination,
      options,
      modules: [Navigation, Pagination],
      CountPosition,
      countSlider,
      getProducts,
      getProductId,
      showAddToCart,
    };
  },
};
</script>
<style>
.back-header {
  height: auto;
}

/* .body-exclusive .exclusive-swiper {
  padding: 20px 55px 0;
} */

/* .exclusive-swiper .exclusive-position {
  width: 36px;
  height: 36px;
  background: rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0px 0px 4px rgb(16 16 1.6%);
  box-shadow: 0px 0px 4px rgb(16 16 1.6%);
  border-radius: 999px;
  position: absolute;
  bottom: 8px;
  top: unset;
}

.exclusive-swiper .exclusive-position::after {
  font-size: 14px;
  color: #484848;
}

.exclusive-swiper .exclusive-position-next {
  right: 20%;
}

.exclusive-swiper .exclusive-position-prev {
  left: 20%;
} */

/* .exclusive-swiper .swiper-slide.swiper-slide-prev {
  transform: rotate(-5deg) scale(0.8) translate(0, 10%) !important;
}

.exclusive-swiper .swiper-slide.swiper-slide-next {
  transform: rotate(5deg) scale(0.8) translate(0, 10%) !important;
}

.exclusive-popup {
  z-index: 1;
} */

/* .modal-exclusive .brands-sale-body {
  height: 100vh !important;
} */
/* 
.dosiin-details-page .note-add-to-cart,
.exclusive-popup .note-add-to-cart {
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #4a4a4a;
} */

.dosiin_modal-login .dosiin_modal-login {
  z-index: 1000 !important;
}
</style>

<template>
  <ExclusiveMB v-if="layout == 4" />
  <ExclusivePC v-else />
</template>

<script>
import ExclusiveMB from "@/pages/mb/Exclusive";
import ExclusivePC from "@/pages/pc/Exclusive";
import EventService from "@/services/event";
import ProductService from "@/services/product";
import { useRoute, useRouter } from "vue-router";
import { ref, provide, onBeforeMount, reactive } from "vue";

export default {
  components: {
    ExclusiveMB,
    ExclusivePC,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const eventId = route.query.event_id;
    const params = ref({});
    const queryParams = route.query;
    const event = ref({});
    const pairsObject = ref({});
    const loading = ref(true);
    const products = ref([]);
    const pagination = ref({});
    const brands = ref([]);
    const page = ref(1);
    const totalPage = ref(0);

    onBeforeMount(() => {
      getEvent();
    });

    async function getEvent() {
      const response = await EventService.fetchEvent(eventId);

      if (!response || response.data.event.length === 0) router.push({ name: 404 });

      params.value = {
        ...response.data.params,
        type_event: response.data.event.type,
        // query from url
        ...queryParams,
      };

      // set default sort
      params.value.sort_by = "random";

      event.value = response.data;
      pairsObject.value = response.data.event.main_pair;
      loading.value = false;
      brands.value =
        response.data.event && response.data.event.companies
          ? response.data.event.companies.slice(0, 4)
          : [];

      getProducts(params.value);
    }

    async function getProducts(query, push = false) {
      loading.value = true;

      // pagination.value.page = state.page;
      // var params = pagination.value;

      const response = await ProductService.fetchProducts(query);

      // if (push) products.value.push(...response.data.products);
      // else products.value = response.data.products;

      products.value = response.data.products;

      pagination.value = response.data.params;
      totalPage.value = Math.ceil(
        pagination.value.total_items / pagination.value.items_per_page
      );

      page.value = pagination.value.page;
      loading.value = false;
    }

    provide("pairsObject", pairsObject);
    provide("loading", loading);
    provide("event", event);
    provide("params", params);
    provide("products", products);
    provide("pagination", pagination);
    provide("totalPage", totalPage);
    provide("brands", brands);
  },
};
</script>
